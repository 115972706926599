import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/cryptoModern';
import { ResetCSS } from 'common/assets/css/style';

import Sticky from 'react-stickynode';
import Navbar from '../containers/CryptoModern/Navbar';
import Footer from '../containers/CryptoModern/Footer';
import GlobalStyle, {
  BaseWrapper,
  ContentWrapper,
} from '../containers/CryptoModern/base.style';
import {ModalProvider} from "../common/contexts/ModalContext";
import { graphql, useStaticQuery } from 'gatsby';
import VeicoliSection from '../containers/CryptoModern/VeicoliSection';

export default function index () {
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
      <ThemeProvider theme={theme}>
        <>
          <ResetCSS />
          <GlobalStyle />
          <div className={"radial"}/>
          <ModalProvider>
            <BaseWrapper>
              <Sticky top={0} innerZ={9999} activeClass="sticky-active">
                <Navbar />
              </Sticky>
              <ContentWrapper id = "veicoli-wrapper">
                <VeicoliSection />
                <Footer />
              </ContentWrapper>
            </BaseWrapper>
          </ModalProvider>
        </>
      </ThemeProvider>
  );
}

export function Head() {

  const detailsQuery = useStaticQuery(graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        keywords
        image
        url
      }
    }
  }
`);

return (
  <>
    <html lang={'it'} />
    <title>{`Veicoli | ${detailsQuery.site.siteMetadata.title} `}</title>
    <meta name="description" content={detailsQuery.site.siteMetadata.description} />
    <meta name="author" content={detailsQuery.site.siteMetadata.author} />
    <meta property="og:title" content={`${detailsQuery.site.siteMetadata.title} | Home`} />
    <meta property="og:description" content={detailsQuery.site.siteMetadata.description} />
    <meta property="og:url" content={detailsQuery.site.siteMetadata.url} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={detailsQuery.site.siteMetadata.image} />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="Nologaroia - Noleggio mezzi aziendali, piattaforme aeree e molto altro" />
    <meta property="og:locale" content="it_IT" />
    <meta property="og:site_name" content={detailsQuery.site.siteMetadata.title} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@Nologaroia" />
    <meta name="twitter:url" content={detailsQuery.site.siteMetadata.url} />
    <meta name="twitter:image" content={detailsQuery.site.siteMetadata.image} />
    <meta name="twitter:creator" content="@Nologaroia" />
    <meta name="twitter:title" content={detailsQuery.site.siteMetadata.title} />
    <meta name="twitter:description" content={detailsQuery.site.siteMetadata.metaDescription} />
    {detailsQuery.site.siteMetadata.keywords.length > 0 && (
      <meta name="keywords" content={detailsQuery.site.siteMetadata.keywords.join(', ')} />
    )}
  </>
);
}